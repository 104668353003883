import React, { Component, useState, useEffect } from 'react'
import $ from 'jquery'
import './App.scss'
import Header from './components/Header'
import Footer from './components/Footer'
import About from './components/About'
import Experience from './components/Experience'
import Projects from './components/Projects'
import Skills from './components/Skills'

import portfolioSharedData from './data/portfolio_shared_data.json'
import primaryLanguageData from './data/res_primaryLanguage.json'
import secondaryLanguageData from './data/res_secondaryLanguage.json'

const LANG_ID_NZ = 'en'
const LANG_ID_TW = 'zh-Hant'

const App = () => {
  const [sharedData, setSharedData] = useState(portfolioSharedData)
  const [resumeData, setResumeData] = useState(primaryLanguageData)

  useEffect(() => {
    applyPickedLanguage(LANG_ID_NZ, LANG_ID_TW)
  }, [])

  const swapCurrentlyActiveLanguage = (oppositeLangIconId) => {
    const pickedLangIconId = oppositeLangIconId === LANG_ID_NZ ? LANG_ID_TW : LANG_ID_NZ
    document.getElementById(oppositeLangIconId).removeAttribute('filter', 'brightness(40%)')
    document.getElementById(pickedLangIconId).setAttribute('filter', 'brightness(40%)')
  }

  const applyPickedLanguage = (pickedLanguage, oppositeLangIconId) => {
    swapCurrentlyActiveLanguage(oppositeLangIconId)
    document.documentElement.lang = pickedLanguage
    setResumeData(pickedLanguage === LANG_ID_NZ ? primaryLanguageData : secondaryLanguageData)
  }

  return (
    <div>
      <Header sharedData={sharedData.basic_info} />
      <div className="col-md-12 mx-auto text-center language">
        <div onClick={() => applyPickedLanguage(LANG_ID_NZ, LANG_ID_TW)} style={{ display: 'inline' }}>
          <span
            className="iconify language-icon mr-5"
            data-icon="twemoji:flag-new-zealand"
            data-inline="false"
            id={LANG_ID_NZ}
          ></span>
        </div>
        <div onClick={() => applyPickedLanguage(LANG_ID_TW, LANG_ID_NZ)} style={{ display: 'inline' }}>
          <span
            className="iconify language-icon"
            data-icon="twemoji:flag-taiwan"
            data-inline="false"
            id={LANG_ID_TW}
          ></span>
        </div>
      </div>
      <About resumeBasicInfo={resumeData.basic_info} sharedBasicInfo={sharedData.basic_info} />
      <Projects resumeProjects={resumeData.projects} resumeBasicInfo={resumeData.basic_info} />
      <Skills sharedSkills={sharedData.skills} resumeBasicInfo={resumeData.basic_info} />
      <Experience resumeExperience={resumeData.experience} resumeBasicInfo={resumeData.basic_info} />
      <Footer sharedBasicInfo={sharedData.basic_info} />
    </div>
  )
}

export default App
